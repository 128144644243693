// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as ContractLength from "../../../../models/ContractLength.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              contractLengths: field.required("contractLengths", Json_Decode$JsonCombinators.array(ContractLength.decoder)),
              accessTypes: field.required("accessTypes", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              portSpeeds: field.required("portSpeeds", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              classOfServices: field.required("classOfServices", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              mplsServices: field.required("mplsServices", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              geographicalRegions: field.required("geographicalRegions", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Options = {
  decoder: decoder,
  fromJson: fromJson
};

export {
  Options ,
}
/* decoder Not a pure module */
